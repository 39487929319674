import Vue from 'vue'
import _ from 'lodash'
import { sync } from 'vuex-router-sync'
import axios from 'axios';
import axiosDefaults from 'axios/lib/defaults';
// import * as VueGoogleMaps from 'vue2-google-maps'
// import * as GmapVue from 'gmap-vue'

import ImageUploader from 'vue-image-upload-resize'
// import Marker from "vue2-google-maps/dist/components/marker";
// import Map from "vue2-google-maps/dist/components/map.vue";

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/tailwind.css'

window.tbs = {
  // googleApiKey: "AIzaSyBcbZ_h9vqt7pEzYet-GCs0MdXhL-Z8i4s", //GJ test 
  googleApiKey: 'AIzaSyC2NPyeuXlOPNAgub5yoLlCBWa5N9D6IZQ', //TBS official (original)
  // googleApiKey: 'AIzaSyAxdvJpYVCZVW-_kzBR71OGnXeJbeTF4Nw', //TBS official (Brian's new account)
  recaptchaSiteKey: "6Ldp8MoZAAAAABxjylgjXdNZccxftDykLVeOKHoW", //https://www.google.com/recaptcha/admin/site/432730217/setup

  // Used for: 
  // - showing stats url when creating broadcast
  // E>G>: http://78.46.109.144:3001/stats/1092133
  isTestMode: false, 
}

const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : location.origin;

console.log("process.env.NODE_ENV", process.env.NODE_ENV)
axiosDefaults.baseURL = baseURL;
axiosDefaults.headers = { 
  'Cache-Control': 'no-cache',
};
// return non-200 codes in then instead of throw
// See: https://github.com/axios/axios/issues/41
axiosDefaults.validateStatus = function () {
  return true;
};

// When response contains 'updateUser'-header -> use it's value (stringified usetr object) to update the user
// User-object may also contain a special object of form:  
axios.interceptors.response.use(resp => {
  if(resp.headers['x-updated-user']){
    const user = JSON.parse(decodeURI(resp.headers['x-updated-user']))
    store.commit("updateUser", user)
  }
  return resp;
}, (error) => {
  return Promise.reject(error.message);
});


Vue.prototype.$http = axios;
Vue.prototype.$baseURL = baseURL;
Vue.config.productionTip = false

Vue.use(ImageUploader);

// Sync state of router into vuex store
sync(store, router);

new Vue({
  router,
  store,
  render: h => h(App),
  // methods: {
  //   async recaptcha(action = null) {
  //     // (optional) Wait until recaptcha has been loaded.
  //     await this.$recaptchaLoaded()
 
  //     // Execute reCAPTCHA with action "login".
  //     const token =  await this.$recaptcha(action)
  //     console.log("token", token);
  //     return token;
  //   }
  // },
}).$mount('#app')
